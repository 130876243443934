document.addEventListener("turbolinks:load", function() {
  var endTimeElement = $("#end-time").get(0);
  if (!!endTimeElement) {
    endTime = endTimeElement.textContent;

    setInterval(() => setTimeLeft(endTime), 100);
  }
});

const MS_IN_SECOND = 1000;
const MS_IN_MINUTE = 60 * MS_IN_SECOND;
const MS_IN_HOUR = 60 * MS_IN_MINUTE;
const MS_IN_DAY = 24 * MS_IN_HOUR;

function setTimeLeft(endTime) {
  timeLeft = endTime - new Date();

  if (timeLeft < 0) {
    return;
  }

  var msInUnits = [MS_IN_DAY, MS_IN_HOUR, MS_IN_MINUTE, MS_IN_SECOND];

  msInUnits.forEach(function(msInUnit, index) {
    let unit = Math.floor(timeLeft / msInUnit);
    timeLeft -= unit * msInUnit;

    $("#digit-" + (2 * index + 1).toString()).text(Math.floor(unit / 10));
    $("#digit-" + (2 * index + 2).toString()).text(unit % 10);
  });
}
